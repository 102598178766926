import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class MapService {

    
    getForwardGeo(data) {
        return axios({
            method: 'get',
            url: `${API_URL}/maps/geo/search`,
            params: data
        });
    }

}

export default new MapService();